export const homeItems = [

    {
        link: "mailto:toptascagri@gmail.com",
        icon: 'mail',
    },
    {
        link: "https://github.com/toptasc",
        icon: 'github',
    },
    {
        link: "https://www.linkedin.com/in/cagri-toptas/",
        icon: 'linkedin',
    },
]
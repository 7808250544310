import { useState, useEffect, useMemo } from "react";
import trFlag from "../assets/images/tr.png";
import enFlag from "../assets/images/gb.png";

const Header = ({ langData, langFunc }) => {
  const [toggle, setToggle] = useState(false);
  const [activeSection, setActiveSection] = useState("home");

  const navLinks = langData.header.navLinks;
  const navs = useMemo(() => ["home", "about", "services", "portfolio", "contact"], []);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    navs.forEach((section) => {
      const sectionElement = document.getElementById(section);
      if (sectionElement) observer.observe(sectionElement);
    });

    return () => {
      navs.forEach((section) => {
        const sectionElement = document.getElementById(section);
        if (sectionElement) observer.unobserve(sectionElement);
      });
    };
  }, [navs]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 3;

      for (const section of navs) {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          const sectionTop = sectionElement.offsetTop;
          const sectionHeight = sectionElement.offsetHeight;

          if (
            scrollPosition >= sectionTop &&
            scrollPosition < sectionTop + sectionHeight
          ) {
            setActiveSection(section);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navs]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offsetTop = section.getBoundingClientRect().top + window.scrollY - 70;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
      setToggle(false);
    }
  };

  return (
    <header className="header">
      <div className="languages">
        <div onClick={() => langFunc("tr")} className="tr">
          <img src={trFlag} alt="Türkçe" />
          <p>TR</p>
        </div>
        <div onClick={() => langFunc("en")} className="en">
          <img src={enFlag} alt="English" />
          <p>EN</p>
        </div>
      </div>

      <i
        className={`bx ${toggle ? "bx-x" : "bx-menu"}`}
        id="menu-icon"
        onClick={() => setToggle(!toggle)}
      ></i>

      <nav className={`navbar ${toggle ? "active" : ""}`}>
        {navLinks.map((item, index) => (
          <a
            key={index}
            href={`#${navs[index]}`}
            className={activeSection === navs[index] ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              scrollToSection(navs[index]);
            }}
          >
            {item}
          </a>
        ))}
      </nav>
    </header>
  );
};

export default Header;

import React from "react";
import { Icon } from "../helpers/Icons";

const Footer = ({ langData }) => {
  const { footer } = langData;

  const scrollToTop = () => {
    const homeSection = document.getElementById("home");
    if (homeSection) {
      const offsetTop = homeSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-text">
        <p>{footer}</p>
      </div>
      <div className="footer-iconTop">
        <button onClick={scrollToTop}>
          <Icon name={"upArrow"} />
        </button>
      </div>
    </footer>
  );
};

export default Footer;

import emailjs from "@emailjs/browser";
import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import ScrollReveal from "scrollreveal";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY_ID = process.env.REACT_APP_PUBLIC_KEY_ID;

const Contact = ({ langData }) => {
  const { contact } = langData;
  const {
    successMsg,
    errMsg,
    title,
    titleSpan,
    formName,
    formEmail,
    formNumber,
    formSubject,
    formMessage,
    button,
  } = contact;

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY_ID).then(
      (result) => {
        e.target.reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: successMsg,
          showConfirmButton: false,
          timer: 1500,
        });
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMsg,
        });
      }
    );
  };

  useEffect(() => {
    const sr = ScrollReveal({
      distance: window.innerWidth > 768 ? "80px" : "40px",
      duration: 2000,
      delay: 200,
      reset: true,
    });
    sr.reveal(".heading", { origin: "top" });
    sr.reveal(".contact form", { origin: "bottom" });
  }, []);

  return (
    <section id="contact" className="contact">
      <h2 className="heading">
        {title} <span>{titleSpan}</span>
      </h2>
      <form ref={form} onSubmit={sendEmail}>
        <div className="input-box">
          <input type="text" name="from_name" placeholder={formName} required />
          <input
            type="email"
            name="from_email"
            placeholder={formEmail}
            required
          />
        </div>
        <div className="input-box">
          <input
            type="number"
            name="from_number"
            placeholder={formNumber}
            required
          />
          <input
            type="text"
            name="from_subject"
            placeholder={formSubject}
            required
          />
        </div>
        <textarea
          name="message"
          cols={30}
          rows={10}
          placeholder={formMessage}
          required
        />
        <button type="submit" className="btn">
          {button}
        </button>
      </form>
    </section>
  );
};

export default Contact;

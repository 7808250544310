import { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import project1 from "../assets/projects/project-1.jpg";
import project2 from "../assets/projects/project-2.jpg";
import project3 from "../assets/projects/project-3.jpg";
import project4 from "../assets/projects/project-4.jpg";
import project5 from "../assets/projects/project-5.jpg";
import project6 from "../assets/projects/project-6.jpg";
import { Icon } from "../helpers/Icons";

const Portfolio = ({ langData }) => {
  useEffect(() => {
    const sr = ScrollReveal({
      distance: window.innerWidth > 768 ? "80px" : "40px",
      duration: 2000,
      delay: 200,
      reset: true,
    });
    sr.reveal(".heading", { origin: "top" });
    sr.reveal(".portfolio-box", { origin: "bottom" });
  }, []);

  const portfolioItemsImages = [
    project5,
    project1,
    project2,
    project4,
    project3,
    project6,
  ];

  const { portfolio } = langData;
  const { title, titleSpan, portfolioItems, button } = portfolio;

  return (
    <section id="portfolio" className="portfolio">
      <h2 className="heading">
        {title} <span>{titleSpan}</span>
      </h2>
      <div className="portfolio-container">
        {portfolioItems.map((item, index) => (
          <div key={index} className="portfolio-box">
            <img src={portfolioItemsImages[index]} alt={item.title} />
            <div className="portfolio-layer">
              <h4>{item.title}</h4>
              <p>{item.text}</p>
              <a href={item.link} target="_blank" rel="noreferrer">
                <Icon name="linkExternal" size={20} />
              </a>
            </div>
          </div>
        ))}
      </div>
      <div className="project-btn">
        <a
          href="https://github.com/furkan-dogu"
          target="_blank"
          rel="noreferrer"
          className="btn"
        >
          {button}
        </a>
      </div>
    </section>
  );
};

export default Portfolio;

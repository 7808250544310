import { useEffect } from "react";
import { homeItems } from "../helpers/homeItems";
import ScrollReveal from "scrollreveal";
import trCv from "../assets/documents/Furkan-Doğu-CV.pdf";
import enCv from "../assets/documents/Furkan-Dogu-Resume.pdf";
import { Icon } from "../helpers/Icons";

const Home = ({ langData }) => {
  useEffect(() => {
    const sr = ScrollReveal({
      distance: window.innerWidth > 991 ? "10%" : "5%",
      duration: 2000,
      delay: 200,
      reset: true,
    });
    sr.reveal(".home-content", { origin: "top" });
    sr.reveal(".home-img", { origin: "bottom" });
    sr.reveal(".home-content h1", { origin: "left" });
    sr.reveal(".home-content p", { origin: "right" });
  }, []);

  const { home, language } = langData;
  const { title, subtitle, subtitleSpan, paragraph, button } = home;

  const cvFunc = () => {
    if (language === "Türkçe") {
      return trCv;
    } else if (language === "English") {
      return enCv;
    }
  };

  return (
    <section id="home" className="home">
      <div className="home-content">
        <h3>{title}</h3>
        <h1>Mehmet Cagri TOPTAS</h1>
        <h3>
          {subtitle} <span>{subtitleSpan}</span>
        </h3>
        <p>{paragraph}</p>
        <div className="social-media">
          {homeItems.map((item, index) => (
            <a key={index} href={item.link} target="_blank" rel="noreferrer">
              <Icon name={item.icon} size={20} />
            </a>
          ))}
        </div>
        <a href={cvFunc()} download className="btn">
          {button}
        </a>
      </div>
    </section>
  );
};

export default Home;

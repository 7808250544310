import { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import { Icon } from "../helpers/Icons";

const icons = ["k8s", "docker", "aws"];

const Services = ({ langData }) => {
  useEffect(() => {
    const sr = ScrollReveal({
      distance: window.innerWidth > 768 ? "80px" : "40px",
      duration: 2000,
      delay: 200,
      reset: true,
    });
    sr.reveal(".heading", { origin: "top" });
    sr.reveal(".services-container", { origin: "bottom" });
  }, []);

  const { services } = langData;
  const { title, titleSpan, serviceItems } = services;

  return (
    <section id="services" className="services">
      <h2 className="heading">
        {title} <span>{titleSpan}</span>
      </h2>

      <div className="services-container">
        {serviceItems.map((item, index) => (
          <div key={index} className="services-box">
            <Icon name={icons[index]} size={70} />
            <h3>{item.title}</h3>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;

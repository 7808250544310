import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import tr from "./assets/languages/turkish.json";
import en from "./assets/languages/english.json";
import { useEffect, useState } from "react";

const getInitialLangData = () => {
  const savedLang = sessionStorage.getItem("selectedLang");
  if (savedLang) {
    return savedLang === "tr" ? tr : en;
  }
  return (navigator.language || navigator.userLanguage).startsWith("tr") ? tr : en;
};

function App() {
  const [langData, setLangData] = useState(getInitialLangData);

  useEffect(() => {
    const langPath = langData === tr ? "/tr" : "/en";
    const currentPath = window.location.pathname;
  
    if (!currentPath.startsWith(langPath)) {
      const newPath = currentPath.replace(/^\/(tr|en)/, "");
      const finalPath = langPath + newPath;

      window.history.replaceState(null, "", finalPath.replace(/\/$/, ""));
    }
  }, [langData]);
  

  const langFunc = (lang) => {
    if (lang === "tr") {
      setLangData(tr);
      sessionStorage.setItem("selectedLang", "tr");
    } else if (lang === "en") {
      setLangData(en);
      sessionStorage.setItem("selectedLang", "en");
    }
  };

  return (
    <>
      <Header langData={langData} langFunc={langFunc} />
      <Home langData={langData} />
      <About langData={langData} />
      <Services langData={langData} />
      <Portfolio langData={langData} />
      <Contact langData={langData} />
      <Footer langData={langData} />
    </>
  );
}

export default App;

import { useEffect } from "react";
import aboutImage from "../assets/images/about.jpg";
import ScrollReveal from "scrollreveal";

const About = ({ langData }) => {
  useEffect(() => {
    const sr = ScrollReveal({
      distance: window.innerWidth > 991 ? "10%" : "5%",
      duration: 2000,
      delay: 200,
      reset: true,
    });
    sr.reveal(".heading", { origin: "top" });
    sr.reveal(".about-img", { origin: "left" });
    sr.reveal(".about-content", { origin: "right" });
  }, []);

  const { about } = langData;
  const { title, titleSpan, paragraph, button } = about;

  return (
    <section id="about" className="about">
      <div className="about-img">
        <img src={aboutImage} alt="Frontend Developer" />
      </div>

      <div className="about-content">
        <h2 className="heading">
          {title} <span>{titleSpan}</span>
        </h2>
        <h3>Devops Engineer</h3>
        <p>{paragraph}</p>
        <a
          href="https://www.linkedin.com/in/cagri-toptas/"
          target="_blank"
          rel="noreferrer"
          className="btn"
        >
          {button}
        </a>
      </div>
    </section>
  );
};

export default About;
